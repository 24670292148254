.Location {
    .title {
        text-align: center;
        margin: 0 auto;
        display: flex;
        justify-content: center;
        width: 100%;
        img {
            max-width: 20%;
            height: auto;
            padding: 0px 1em;
            &:last-child {
                rotate: 180deg;
            }
        }
        @media (max-width: 1023px) {
            display: inline;
            img {
                display: none;
            }
        }
    }
    .content {
        @media (min-width: 1024px) {
            .elem {
                display: inline-grid;
                width: 50%;
                .content {
                    padding: 0px 1em;
                }
            }
        }
    }
}