.Contact {
    .content {
        position: relative;
        .couple {
            width: 100%;
            a {
                position: relative;
                z-index: 10;
                text-decoration: none;
                border-bottom: 3px solid #7b9291;
            }
            @media screen and (min-width: 1024px) {
                .groom, .bride {
                    display: inline-block;
                    width: 50%;
                    font-size: 36px;
                    font-weight: bold;
                }
                .bride {
                    text-align: right;
                }
            }
            @media screen and (max-width: 1023px) {
                .groom, .bride {
                    text-align: center;
                    font-size: 36px;
                    font-weight: bold;
                }
            }
        }
        .together {
            @media screen and (min-width: 1024px) {
                position: absolute;
                top: 0px;
                left: 0px;
                width: 100%;
                img {
                    transform: translate(75%, 0px);
                    margin: 0 auto;
                    max-width: 40%;
                }
            }
            @media screen and (max-width: 1023px) {
                img {
                    max-width: 100%;
                }
            }
        }
        .arrows {
            @media screen and (max-width: 1023px) {
                display: none;
            }
            img {
                position: absolute;
                max-width: 20%;
            }
            .arrowGroom {
                top: 30%;
                left: 10vw;
                transform: scaleY(-1);
            }
            .arrowBride {
                top: 30%;
                right: 180px;
                transform: rotate(180deg);
            }
        }
        .mysteryGift {
            text-align: center;
            @media (min-width: 1024px) {
                margin-top: 5em;
            }
        }
    }
}