body {
  margin: 0;
  font-family: 'Darker Grotesque', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #F4F9E5;
  color: #7b9291;
  min-height: 100dvh;
  max-width: 1280px;
  margin: 0 auto;
  padding: 0px 2em;
  font-size: 24px;
}

@media (max-width: 1023px) {
  body {
    padding: 2em;
  }
}

a {
  color: #7b9291;
}

.left {
  text-align: left;
}

.center {
  text-align: center;
}

.right {
  text-align: right;
}

.bold {
  font-weight: bold;
}

.ligature {
  font-family: 'Dancing Script', cursive;
}

.underline {
  text-decoration: underline;
}

.inline-block {
  display: inline-block;
}

.justify-between {
  justify-content: space-between;
}

.hidden[class] {
  opacity: 0;
  cursor: default !important;
}

.form-element-textarea {
  resize: none;
  display: block;
}
