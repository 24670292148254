.Home {
    .top {
        @media (max-width: 1023px) {
            text-align: center;
            padding-top: 2em;
        }
        img {
            @media (min-width: 1024px) {
                max-width: 33%;
                height: auto;
            }
            @media (max-width: 1023px) {
                max-width: 80%;
            }
        }
    }
    .intro {
        .jumbo {
            font-size: 40px;
        }
    }
    .signature {
        font-size: 40px;
    }
}