.Confirm {
    .content {
        text-align: center;
        textarea {
            width: 100%;
            display: block;
            margin-left: auto;
            margin-right: auto;
        }
        input, textarea {
            margin: 0 auto;
            color: #ffffff;
            background-color: #7b9291;
            border: none;
            padding: 8px;
            border-radius: 6px;
            font-family: 'Darker Grotesque', sans-serif;
            font-size: 20px;
            margin: 10px 0px;
        }
        ::placeholder {
            color: #eeeeee;
        }
        .formSection {
            @media (max-width: 1023px) {
                display: flex;
            }
            @media (min-width: 1024px) {
                width: 50%;
                display: inline-flex;
            }
            flex-direction: column;
            align-items: center;
        }
        .people {
            .formElement {
                width: 100%;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;
                padding: 1em 0px;
            }
            .addPersonBtn {
                background-color: transparent;
                color: #7b9291;
                border: 1px solid #7b9291;
                border-radius: 6px;
                font-size: 24px;
                padding: 4px 12px;
                margin-top: 1em;
                cursor: pointer;
            }
            .removePersonBtn {
                background-color: transparent;
                color: #7b9291;
                border: 1px solid #7b9291;
                border-radius: 6px;
                font-size: 20px;
                padding: 4px 12px;
                cursor: pointer;
                @media (min-width: 1024px) {
                    margin-left: 1em;
                }
                &:disabled {
                    cursor: not-allowed;
                    color: gray;
                    border: 1px dotted gray;
                }
            }
        }
        .details {
            align-items: flex-start;
            .detailsElement {
                margin: 0 auto;
                @media (min-width: 1024px) {
                    width: 70%;
                }
                input[type=text] {
                    width: 100%;
                }
            }
        }
    }
    .confirmBtn {
        background-color: transparent;
        color: #7b9291;
        border: 1px solid #7b9291;
        border-radius: 6px;
        font-size: 24px;
        padding: 4px 12px;
        margin-top: 1em;
        cursor: pointer;
    }
}