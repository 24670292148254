.navbar {
    ul {
        width: 100%;
        margin: 0 auto;
        margin-block-start: 0px;
        margin-block-end: 0px;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
        padding-inline-start: 0px;
        list-style-type: none;
        display: inline-flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: center;
        justify-content: space-evenly;
        margin-top: 1em;
        @media screen and (max-width: 1023px) {
            margin-top: 0px;
            flex-direction: column;
        }
        li {
            font-size: 40px;
            font-family: "Dancing Script", cursive;
            text-align: center;
            @media screen and (max-width: 1023px) {
                &:not(:last-child) {
                    border-bottom: 1px solid #7b9291;
                }
                padding: .25em;
                &:first-child {
                    padding-top: 0px;
                }
                width: 100%;
            }
            a {
                text-decoration: none;
                color: #7b9291;
            }
        }
    }
    .current {
        text-decoration: underline;
        font-weight: bold;
    }
}