.title {
    text-align: center;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    width: 100%;
    img {
        max-width: 20%;
        height: auto;
        padding: 0px 1em;
        &:last-child {
            rotate: 180deg;
        }
    }
    @media (max-width: 1023px) {
        display: inline;
        img {
            display: none;
        }
    }
}